<template>
  <AdminReportingForm
    title="Reservation Methods"
    description="The Reservation Methods Report is at the park level and provides
      reservation figures for that park broken down by sales channel for the
      date range specified. This report can be run for any 365 day date range."
    :bullets="descriptionBullets"
    file="ReservationMethods$"
    :reportStatus="reportStatus"
    :submitting="submitting"
    :endDateRequired="false"
    :allowGlobal="true"
    @getReport="getReport"
    startDateLabel="Reservation booking Start Date"
    endDateLabel="Reservation booking End Date"
  />
</template>

<script>
import AdminReportingForm from "@/components/admin/AdminReportingForm.vue";
import AdminReportingService from "@/services/admin/AdminReportingService.js";
import moment from "moment";

export default {
  name: "ReservationMethodReport",
  title: "Admin - Reservation Methods Report",
  data() {
    return {
      reportStatus: "",
      submitting: false,
      descriptionBullets: [
        "Report includes active reservations made on the date selected.",
        "The data returned on this report references only the transaction date that each active reservation was originally requested on. Reservation arrival/departure date information is not referenced."
      ]
    };
  },
  components: {
    AdminReportingForm
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    getReport(reportData) {
      this.reportStatus = "";
      this.submitting = true;
      const date = moment();
      reportData.dateRun = date.format("MM/DD/YYYY hh:mm:ss A");
      reportData.startDate = moment(
        reportData.startDate,
        "MM/DD/YYYY"
      ).format();
      reportData.endDate = moment(reportData.endDate, "MM/DD/YYYY").format();

      const service = new AdminReportingService(this.tenantId);
      service
        .getReservationMethodReport(reportData)
        .then(response => {
          if (response.deliveryMethod == "Download") {
            const filename = reportData.filename.replace(
              "$",
              date.format("MM_DD_YYYY_hh_mm_ss_A")
            );
            service.forceFileDownload(response, filename);
          } else {
            this.reportStatus = "Report sent!";
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>
